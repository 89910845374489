import { useTranslation } from "react-i18next";

const Perks = () => {

	const {t} = useTranslation()

	return (
		<div className="w-full mt-16">
			<div className="container mx-auto">
				<div className="flex flex-col w-full md:flex-row justify-center md:justify-evenly items-center space-y-8 space-x-0 md:space-y-0 md:space-x-8">
					<div className="text-center transition-all md:w-full w-4/5 duration-300 flex flex-col items-center justify-center border-2 border-blue-400 p-8 rounded-xl shadow-none transform hover:border-transparent hover:shadow-xl hover:-translate-y-4">
						<i className="fas fa-award text-5xl text-red-600"></i>
						<h4 className="my-3 font-semibold text-lg text-blue-500 tracking-wider uppercase">{t("q1")}</h4>
						<p>{t("qP1")}</p>
					</div>
					<div className="text-center transition-all md:w-full w-4/5 duration-300 flex flex-col items-center justify-center border-2 border-blue-400 p-8 rounded-xl shadow-none transform hover:border-transparent hover:shadow-xl hover:-translate-y-4">
						<i className="fas fa-piggy-bank text-5xl text-red-600"></i>
						<h4 className="my-3 font-semibold text-lg text-blue-500 tracking-wider uppercase">{t("q2")}</h4>
						<p>{t("qP2")}</p>
					</div>
					<div className="text-center transition-all md:w-full w-4/5 duration-300 flex flex-col items-center justify-center border-2 border-blue-400 p-8 rounded-xl shadow-none transform hover:border-transparent hover:shadow-xl hover:-translate-y-4">
						<i className="fas fa-paint-roller text-5xl text-red-600"></i>
						<h4 className="my-3 font-semibold text-lg text-blue-500 tracking-wider uppercase">{t("q3")}</h4>
						<p>{t("qP3")}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Perks;
