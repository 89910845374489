import React from "react";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Products from "./components/Products";
import Contact from "./components/Contact/Contact";
import About from "./components/About";

export default function App() {
	return (
		<main className="bg-gray-50 w-full">
			<Navbar />
			<Home />
			<About />
			<Products />
			<Contact />
			<Footer />
		</main>
	);
}