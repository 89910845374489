import { useState, useRef, useEffect } from "react";

import Scrollspy from "react-scrollspy";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

import Langdrop from "./Langdrop";

export default function Navbar() {
	const { t } = useTranslation();

	const [navbar, setNavbar] = useState(false);
	const [openNav, setOpenNav] = useState(false);

	const changeBackground = () => {
		if (window.scrollY >= 2) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};

	const ref = useRef();
	const btnRef = useRef();

	const handleClick = e => {
		if (ref.current.contains(e.target)) {
			return;
		}
		if (btnRef.current.contains(e.target)) {
			return;
		}

		setOpenNav(false);
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClick);
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	window.addEventListener("scroll", changeBackground);

	return (
		<header
			className={
				navbar
					? "bg-white py-2 shadow-lg fixed z-40 w-full transition-all duration-300 ease-in-out border-none"
					: "bg-white py-2 sm:bg-transparent fixed z-40 w-full transition-all duration-300 ease-in-out border-b border-gray-400"
			}
		>
			<div className="container mx-auto px-2 sm:px-0">
				<div className="relative flex items-center justify-between h-16">
					<div className="inset-y-0 flex items-center sm:hidden">
						{openNav ? (
							<button
								ref={btnRef}
								onClick={() => setOpenNav(false)}
								className="transition-all duration-300 w-10 h-10 flex items-center justify-center p-2 rounded-md text-gray-800 hover:text-gray-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
							>
								<span className="sr-only">
									Chiudere il menu
								</span>
								<i class="fa-solid fa-xmark text-xl text-center"></i>
							</button>
						) : (
							<button
								ref={btnRef}
								onClick={() => setOpenNav(true)}
								className="transition-all duration-300 w-10 h-10 flex items-center justify-center p-2 rounded-md text-gray-800 hover:text-gray-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
							>
								<span className="sr-only">Aprire il menu</span>
								<i class="fa-solid fa-bars text-xl text-center"></i>
							</button>
						)}
					</div>
					<div className="flex-1 flex items-center justify-center sm:justify-between">
						<Link
							to="home"
							spy={false}
							smooth={true}
							duration={800}
							className="logo cursor-pointer pt-1 md:pt-0 text-4xl sm:text-5xl font-bold tracking-wider text-blue-400"
						>
							Hutsulyak Ivan
						</Link>
						<div className="hidden sm:flex items-center justify-center sm:mr-6">
							<div>
								<Scrollspy
									items={[
										"home",
										"about",
										"prodotti",
										"contatto",
									]}
									offset={-300}
									currentClassName="activeLink border-b-2 border-blue-400"
									className="flex items-center space-x-5"
								>
									<Link
										to="home"
										spy={false}
										smooth={true}
										duration={800}
										className={
											"cursor-pointer px-2 capitalize transition-all duration-300 py-2 text-sm font-medium hover:text-blue-200 hover:border-blue-200 border-b-2 border-transparent " +
											(navbar ? "" : "text-white")
										}
									>
										{t("home")}
									</Link>
									<Link
										to="about"
										spy={false}
										smooth={true}
										duration={800}
										className={
											"cursor-pointer px-2 capitalize transition-all duration-300 py-2 text-sm font-medium hover:text-blue-200 hover:border-blue-200 border-b-2 border-transparent " +
											(navbar ? "" : "text-white")
										}
									>
										{t("about")}
									</Link>
									<Link
										to="prodotti"
										spy={false}
										smooth={true}
										duration={800}
										className={
											"cursor-pointer px-2 capitalize transition-all duration-300 py-2 text-sm font-medium hover:text-blue-200 hover:border-blue-200 border-b-2 border-transparent " +
											(navbar ? "" : "text-white")
										}
									>
										{t("services")}
									</Link>
									<Link
										to="contatto"
										spy={false}
										smooth={true}
										offset={-100}
										duration={800}
										className={
											"cursor-pointer px-2 capitalize transition-all duration-300 py-2 text-sm font-medium hover:text-blue-200 hover:border-blue-200 border-b-2 border-transparent " +
											(navbar ? "" : "text-white")
										}
									>
										{t("contact")}
									</Link>
								</Scrollspy>
							</div>
						</div>
					</div>

					<Langdrop navbar={navbar}></Langdrop>
				</div>
			</div>

			<div
				ref={ref}
				className={
					openNav
						? "sm:hidden max-h-[195px] overflow-hidden transition-all duration-300 ease-in-out"
						: "sm:hidden max-h-[0px] overflow-hidden transition-all duration-300 ease-in-out"
				}
			>
				<nav className="px-2 pt-2 pb-3 space-y-1">
					<Link
						onClick={() => setOpenNav(false)}
						activeClass="bg-blue-300 text-white"
						to="home"
						spy={false}
						smooth={true}
						offset={-100}
						duration={800}
						className="cursor-pointer capitalize transition-all duration-300 block px-3 py-2 text-base font-medium hover:bg-blue-200 hover:text-white"
					>
						{t("home")}
					</Link>
					<Link
						onClick={() => setOpenNav(false)}
						activeClass="bg-blue-300 text-white"
						to="about"
						spy={false}
						smooth={true}
						offset={-100}
						duration={800}
						className="cursor-pointer capitalize transition-all duration-300 block px-3 py-2 text-base font-medium hover:bg-blue-200 hover:text-white"
					>
						{t("about")}
					</Link>
					<Link
						onClick={() => setOpenNav(false)}
						activeClass="bg-blue-300 text-white"
						to="prodotti"
						spy={false}
						smooth={true}
						offset={-100}
						duration={800}
						className="cursor-pointer capitalize transition-all duration-300 block px-3 py-2 text-base font-medium hover:bg-blue-200 hover:text-white"
					>
						{t("services")}
					</Link>
					<Link
						onClick={() => setOpenNav(false)}
						activeClass="bg-blue-300 text-white"
						to="contatto"
						spy={false}
						smooth={true}
						offset={-100}
						duration={800}
						className="cursor-pointer capitalize transition-all duration-300 block px-3 py-2 text-base font-medium hover:bg-blue-200 hover:text-white"
					>
						{t("contact")}
					</Link>
				</nav>
			</div>
		</header>
	);
}
