import React from "react";
import Perks from "./Perks";
import Companies from "./Companies"
import { useTranslation } from "react-i18next";

const About = () => {

	const {t} = useTranslation()

	return (
		<section id="about">
			<div className="w-full py-24">
				<div className="container mx-auto">
					<div className="flex flex-col md:flex-row justify-between items-center">
						<div className="flex w-full md:w-1/2 p-8">
							<img
								src="/img/paint.svg"
								alt="Pittura"
								className="w-full"
							/>
						</div>
						<div className="flex flex-col justify-start items-start w-full md:w-1/2 px-6 md:px-10">
							<h1 className="font-bold text-3xl  text-blue-400">
								{t("Us")}
							</h1>
							<hr className="w-24 h-1 bg-red-500 border-none rounded-full my-4" />
							<p className="text-base md:text-lg">
								{t("pUs")}
							</p>
							<Companies />
						</div>
					</div>
					<Perks />
				</div>
			</div>
		</section>
	);
};

export default About;
