import React from 'react'
import { useTranslation } from 'react-i18next';

const SentBtn = () => {

	const {t} = useTranslation()

	return (
		<button
			type="submit"
			className="uppercase transition-all duration-300 text-base shadow-lg font-bold tracking-wide bg-green-500 text-gray-100 p-3 rounded-md w-full focus:outline-none focus:shadow-outline"
		>
			{t("formSent")}
			<i className="far fa-check-circle ml-2 text-base text-white"></i>
		</button>
	);
}

export default SentBtn
