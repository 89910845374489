import React from 'react'
import { useTranslation } from 'react-i18next';

const SendBtn = () => {
	const { t } = useTranslation();

	return (
		<button
			type="submit"
			className="uppercase transition-all duration-300 text-base shadow-lg font-bold tracking-wide bg-blue-500 text-gray-100 p-3 rounded-md w-full hover:text-blue-400 hover:bg-white focus:outline-none focus:shadow-outline"
		>
			{t("formSend")}
			<i className="fas fa-paper-plane ml-2"></i>
		</button>
	);
}

export default SendBtn
