export const Imgs = [
	{
		id: 1,
		src: "../img/cappotto_1.jpeg",
		alt: "Cappotto",
	},
	{
		id: 2,
		src: "../img/cappotto_2.jpeg",
		alt: "Cappotto",
	},
	{
		id: 3,
		src: "../img/parati_1.jpeg",
		alt: "Parati",
	},
	{
		id: 4,
		src: "../img/parati_2.jpeg",
		alt: "Parati",
	},
	{
		id: 5,
		src: "../img/carton_1.jpeg",
		alt: "Cartongesso",
	},
	{
		id: 6,
		src: "../img/carton_2.jpeg",
		alt: "Cartongesso",
	},
	{
		id: 7,
		src: "../img/imbianc_1.jpeg",
		alt: "Imbiancatura",
	},
	{
		id: 8,
		src: "../img/imbianc_2.jpeg",
		alt: "Imbiancatura",
	},
	{
		id: 9,
		src: "../img/resina_1.jpeg",
		alt: "Resina",
	},
	{
		id: 10,
		src: "../img/resina_2.jpeg",
		alt: "Resina",
	},
	{
		id: 11,
		src: "../img/resina_3.jpg",
		alt: "Resina",
	},
	{
		id: 12,
		src: "../img/resina_4.jpg",
		alt: "Resina",
	},
	{
		id: 13,
		src: "../img/resina_5.jpg",
		alt: "Resina",
	},
	{
		id: 14,
		src: "../img/resina_6.jpg",
		alt: "Resina",
	},
	{
		id: 15,
		src: "../img/resina_7.jpg",
		alt: "Resina",
	},
	{
		id: 16,
		src: "../img/legno_1.jpeg",
		alt: "Legno",
	},
	{
		id: 17,
		src: "../img/legno_2.jpeg",
		alt: "Legno",
	},
];
