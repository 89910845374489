import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import { Link } from "react-scroll";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

import Slider from "./Slider/Slider";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const Products = () => {
	const [openTab, setOpenTab] = useState(0);

	const tab = index => {
		setOpenTab(index);
	};

	const { t } = useTranslation();

	return (
		<section id="prodotti">
			<div className="w-full mb-16 py-24">
				<div className="container mx-auto">
					<div className="w-full md:w-1/2 mb-5 px-4 md:px-0">
						<h1 className="font-bold text-3xl mb-5 text-blue-400">
							{t("btn1")}
						</h1>
						<p>{t("pIntro")}</p>
					</div>
					<div className="flex md:flex-row flex-col items-start justify-between px-5 md:px-0">
						<div className="w-full md:w-1/2 order-2 md:order-1">
							<Tab.Group
								defaultIndex={0}
								onChange={index => {
									tab(index);
								}}
							>
								<Tab.List className="flex flex-col md:flex-row p-1 space-x-0 md:space-x-1 bg-blue-100 rounded-md">
									{i18next
										.t("test", { returnObjects: true })
										.map(tab => (
											<Tab
												key={tab.id}
												className={({ selected }) =>
													classNames(
														"transition-all duration-300 bg-transparent w-full py-2.5 text-sm leading-5 font-medium text-blue-500 outline-none focus:outline-none rounded",
														selected
															? "bg-white shadow"
															: "bg-transparent hover:text-white shadow-none"
													)
												}
											>
												{t(tab.tabName)}{" "}
											</Tab>
										))}
								</Tab.List>
								<Tab.Panels className="mt-5">
									{i18next
										.t("test", { returnObjects: true })
										.map(tab => (
											<Tab.Panel
												className="leading-relaxed tabAnimation"
												key={tab.id}
											>
												<h2 className="font-semibold text-blue-400 text-2xl">
													{t(tab.tabTitle)}
												</h2>
												<hr className="bg-red-500 w-20 h-1 my-3 rounded-full" />
												<p className="mb-5">
													{t(tab.tabText)}
												</p>
												<div className="whitespace-pre-wrap">
													{tab.tabContent.map(sub => (
														<div
															key={t(
																sub.subTitle
															)}
														>
															<h2
																className={
																	sub.subTitle ===
																	""
																		? "hidden"
																		: "font-medium text-lg"
																}
															>
																{t(
																	sub.subTitle
																)}
															</h2>
															<p
																className={
																	sub.subText ===
																	""
																		? "hidden"
																		: "my-3 pb-2"
																}
															>
																{t(sub.subText)}
															</p>
														</div>
													))}
												</div>
												<Link
													to="contatto"
													smooth={true}
													offset={-100}
													duration={700}
												>
													<button className="cursor-pointer transition-all duration-300 flex items-center bg-blue-400 px-6 py-2 rounded shadow-md text-white font-medium text-center border-2 border-transparent hover:bg-transparent hover:border-blue-400 hover:text-blue-400 hover:shadow-none">
														{t("infoBtn")}{" "}
														<i className="far fa-envelope ml-2 text-lg"></i>
													</button>
												</Link>
											</Tab.Panel>
										))}
								</Tab.Panels>
							</Tab.Group>
						</div>

						<Slider openTab={openTab} />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Products;
