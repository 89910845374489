/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import ReactCountryFlag from "react-country-flag";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const languages = [
	{
		code: "IT",
		name: "Italiano",
		country_code: "it",
	},
	{
		code: "DE",
		name: "Deutsch",
		country_code: "de",
	},
];

export default function Langdrop({ navbar }) {
	const { t } = useTranslation();

	return (
		<Menu as="div" className="relative inline-block text-left">
			<div>
				<Menu.Button
					className={
						"hidden lg:flex transition-all duration-300 items-center justify-center w-full rounded-lg border-2 border-blue-200 py-1 px-6 bg-transparent font-medium hover:bg-blue-400 hover:text-white outline-none " +
						(navbar ? "text-blue-400" : "text-white")
					}
				>
					<i className="fas fa-globe mr-2"></i>
					{t("langOp")}
					<i className="fas fa-chevron-down ml-2 text-xs"></i>
				</Menu.Button>
				<Menu.Button className="flex lg:hidden transition-all duration-300 items-center justify-center w-full rounded-lg border-2 border-blue-200 shadow-sm py-2 px-4 bg-transparent font-medium text-blue-400 hover:bg-blue-400 hover:text-white outline-none">
					<i className="fas fa-globe"></i>
					<i className="fas fa-chevron-down ml-2 text-xs"></i>
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="origin-top-right absolute right-0 w-48 px-2 py-1 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						{languages.map(country => (
							<Menu.Item key={country.country_code}>
								<button
									className="flex justify-start items-center w-full mx-auto text-left rounded px-2 py-2 text-sm font-semibold hover:bg-blue-100"
									onClick={() =>
										i18next.changeLanguage(
											country.country_code
										)
									}
								>
									<ReactCountryFlag
										countryCode={country.code}
										svg
										className="mr-2 text-xl"
									/>
									{country.name}
								</button>
							</Menu.Item>
						))}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
}
