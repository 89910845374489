import React from 'react'

const Footer = () => {
	return (
		<footer className="w-full bg-gray-200 py-4">
			<div className="container mx-auto text-center flex items-center justify-center">
				<p>© 2021 Hutsulyak Ivan | Tutti i diritti riservati</p>
			</div>
		</footer>
	);
}

export default Footer
