import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";
import SendBtn from "./SendBtn";
import SentBtn from "./SentBtn";

const Contact = () => {
	const [result, showResult] = useState(false);

	const {t} = useTranslation()

	const {
		register,
	} = useForm();

	const form = useRef();

	const sendEmail = e => {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_gqsevq4",
				"template_qtfll2p",
				form.current,
				"user_rnHN9vdFMwZX17MVJOTQA"
			)
			.then(
				result => {
					console.log(result.text);
				},
				error => {
					console.log(error.text);
				}
			);
		e.target.reset();
		showResult(true);
	};

	setTimeout(() => {
		showResult(false);
	}, 4000);

	return (
		<section id="contatto" className="container mx-auto pb-16 px-4 md:px-0">
			<div className="max-w-screen mt-10 px-0 md:px-16 py-16 mx-auto text-gray-900 rounded-xl border-2 border-blue-500">
				<div className="w-full flex flex-col md:flex-row items-center justify-between">
					<div className="w-full md:w-1/2 flex flex-col justify-start items-start px-6 md:px-4">
						<div>
							<h2 className="text-4xl lg:text-5xl font-bold leading-tight ">
								{t("formTitle")}
							</h2>
						</div>
						<div className="mt-8 text-center">
							<img
								src="/img/contact.svg"
								alt="Contatto"
								className="w-full md:w-4/5 my-6"
							/>
						</div>
						<div className="flex items-start flex-col w-full md:w-2/4 px-4 py-2 mt-10">
							<div className="flex items-center my-1 text-lg">
								<i className="fas fa-phone-alt mr-3 text-blue-600"></i>
								<a
									href="tel:+393898289795"
									className="transition-all duration-300 font-medium hover:text-gray-400"
								>
									+39 389 828 9795
								</a>
							</div>
							<div className="flex items-center my-1 text-lg">
								<i className="fas fa-map-marked-alt mr-3 text-blue-600"></i>
								<p className="font-medium">
									Cavalese via Valle 2, <br /> TN 38033
								</p>
							</div>
							<div className="flex items-center my-1 text-lg">
								<i className="fas fa-envelope mr-3 text-blue-600"></i>
								<a
									href="mailto:contatto@ivan-color.com"
									className="transition-all duration-300 font-medium hover:text-gray-400"
								>
									contatto@ivan-color.com
								</a>
							</div>
						</div>
					</div>

					<form
						ref={form}
						onSubmit={sendEmail}
						className="w-full md:w-1/2 p-2 py-6 md:py-8 md:p-8 mt-10 md:mt-0 bg-blue-200 rounded-none md:rounded-xl shadow-xl"
					>
						<div>
							<label className="uppercase text-sm text-gray-600 font-bold">
								{t("formName")}
							</label>
							<input
								{...register("name", {
									required: true,
								})}
								name="name"
								className="w-full bg-gray-100 shadow-lg text-gray-900 mt-2 p-3 rounded-md focus:outline-none focus:shadow-outline"
								type="text"
								placeholder={t("formName")}
								required
							/>
						</div>
						<div className="mt-8">
							<label className="uppercase text-sm text-gray-600 font-bold">
								{t("formEmail")}
							</label>
							<input
								{...register("email", {
									required: true,
								})}
								name="email"
								required
								className="w-full bg-gray-100 shadow-lg text-gray-900 mt-2 p-3 rounded-md focus:outline-none focus:shadow-outline"
								type="email"
								placeholder={t("formEmail")}
							/>
						</div>
						<div className="mt-8">
							<label className="uppercase text-sm text-gray-600 font-bold">
								{t("formMsg")}
							</label>
							<textarea
								placeholder={t("formMsg")}
								{...register("message", {
									required: true,
								})}
								required
								name="message"
								className="w-full h-72 bg-gray-100 shadow-lg text-gray-900 mt-2 p-3 rounded-md focus:outline-none focus:shadow-outline"
							></textarea>
						</div>
						<div className="mt-8">
							{result ? <SentBtn /> : <SendBtn />}
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export default Contact;
