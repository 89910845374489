import React, { useState } from "react";
import { Imgs } from "./sliderImgs";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination } from "swiper";

const Slider = () => {
	return (
		<div className="flex flex-col justify-center items-center relative my-5 md:my-0 overflow-hidden w-full md:w-1/2 order-1 md:order-2 mb-10 md:mb-0 px-0 md:px-8">
			<Swiper
				slidesPerView={1}
				spaceBetween={30}
				loop={true}
				autoHeight={true}
				pagination={{
					clickable: true,
				}}
				navigation={true}
				modules={[Pagination, Navigation]}
				className="portfolioSwiper"
			>
				{Imgs.map((img, index) => (
					<SwiperSlide key={index}>
						<img
							src={img.src}
							alt={img.alt}
							className="rounded-xl"
						/>
						<p className="transition-all duration-500 absolute z-20 left-0 top-0 text-white w-fit bg-black bg-opacity-40 rounded-br-xl rounded-tl-2xl backdrop-filter backdrop-blur-sm p-3 font-medium text-sm md:text-lg">
							{img.alt}
						</p>
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default Slider;
