import React from "react";

const Companies = () => {
	return (
		<div className="container mx-auto mt-24">
			<div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
				<img src="/img/amonn.svg" alt="Amonn" className="w-32" />
				<img
					src="/img/nordwal.png"
					alt="Nordwal"
					className="w-56 my-12 md:my-0 ml-8 md:ml-3"
				/>
				<img src="/img/elekta.png" alt="Elekta" className="w-28"/>
			</div>
		</div>
	);
};

export default Companies;
