import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

const Home = () => {
	const { t } = useTranslation();

	return (
		<section id="home" className="min-h-screen overflow-hidden">
			<div className="absolute top-0 w-full min-h-screen bg-black opacity-40"></div>
			<div className="w-full h-screen flex items-center md:items-end justify-center md:pb-16 relative z-20">
				<div className="container mx-auto  px-6 sm:px-0 flex flex-col md:flex-row items-center justify-between">
					<div className="flex flex-col items-start text-left w-full md:w-1/2">
						<h1 className="text-4xl md:text-7xl font-bold text-white">
							{t("introTitleP1")}{" "}
							<span className="text-blue-400">
								{t("introTitleP2")}{" "}
							</span>
							{t("introTitleP3")} {t("introTitleP4")}
						</h1>
						<hr className="w-24 h-1 border-none bg-red-500 rounded-full my-8" />
						<p className="text-base md:text-lg text-white mb-10">
							{t("introText")}
						</p>
						<Link
							to="prodotti"
							smooth={true}
							offset={-10}
							duration={700}
						>
							<button className="transition-all duration-300 bg-blue-400 px-6 py-2 rounded shadow-md text-white font-medium flex items-center text-center border-2 border-transparent hover:bg-transparent hover:border-blue-400 hover:shadow-none">
								{t("btn1")}{" "}
								<i className="fas fa-paint-roller ml-2"></i>
							</button>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Home;
